import {NgxUiLoaderConfig, PB_DIRECTION, POSITION, SPINNER} from 'ngx-ui-loader';
import {NotifierOptions} from 'angular-notifier';
import * as moment from 'moment/moment';
import 'moment/locale/ru';

moment.locale('ru');


export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
	bgsColor: '#OOACC1',
	bgsOpacity: 0.3,
	bgsPosition: POSITION.bottomCenter,
	bgsSize: 60,
	bgsType: SPINNER.rectangleBounce,
	fgsColor: '#fff',
	fgsPosition: POSITION.centerCenter,
	// logoPosition: POSITION.topCenter,
	fgsSize: 60,
	fgsType: SPINNER.threeStrings,
	// logoUrl: 'assets/img/owl-logo-square.png',
	// logoSize: 200,
	pbColor: '#19c158',
	pbDirection: PB_DIRECTION.leftToRight,
	pbThickness: 5,
	// text: 'Owl SCADA. Загрузка данных...',
	textColor: '#fff',
	textPosition: POSITION.centerCenter,
};


export const notifierOptions: NotifierOptions = {
	position: {
		horizontal: {
			position: 'middle',
			distance: 12
		},
		vertical: {
			position: 'top',
			distance: 20,
			gap: 5
		}
	},
	theme: 'material',
	behaviour: {
		autoHide: 5000,
		onClick: false,
		onMouseover: 'pauseAutoHide',
		showDismissButton: true,
		stacking: 3
	},
	animations: {
		enabled: true,
		show: {
			preset: 'slide',
			speed: 300,
			easing: 'ease'
		},
		hide: {
			preset: 'fade',
			speed: 300,
			easing: 'ease',
			offset: 50
		},
		shift: {
			speed: 300,
			easing: 'ease'
		},
		overlap: 150
	}
};



