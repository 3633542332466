@if (isLoggedIn) {
	<div>
		<a
			class="usermenu-button g-unselectable"
			title="Пользовательское меню"
			[matMenuTriggerFor]="menu"
			[ngClass]="{'active': isOpenUserMenu}"
			(click)="toggleUserMenu()"
		>
			@if (this.currentUser && this.currentUser['image']) {
				<span>
					<img
						class="menu_btn pointer"
						[src]="this.userImage"
					>
				</span>
			}

			@if (this.currentUser && this.currentUser['image'] === null) {
				<span class="menu_btn pointer menu_txt"> {{ userName | slice:0:1 }} </span>
			}

			@if (notification?.length) {
				<span class="notif_count_txt notif_count_txt_marg">{{ notification?.length }}</span>
			}
		</a>

		<mat-menu #menu="matMenu">
			<div class="menu g-unselectable">
				<span class="menu__item">
					<app-icon name="person"></app-icon>
					<span>{{ userName }}</span>
				</span>

				<a
					class="link menu__item"
					(mouseup)="openQrModal()"
				>
					<app-icon name="qr-code"></app-icon>
					<span>QR</span>
				</a>

				@if (hasAccessToAdmin) {
					<!--					<span-->
						<!--						class="menu__item"-->
						<!--						(mouseup)="goToPage('/personal-area', $event)"-->
						<!--					>-->
						<!--						<app-icon name="statistics"></app-icon>-->
						<!--						<span>Личный кабинет</span>-->
						<!--					</span>-->

					<a
						class="menu__item"
						(mouseup)="goToPage('/adm', $event)"
					>
						<app-icon name="lock-closed"></app-icon>
						<span>Административная панель</span>
					</a>
				}

				@if (isFullUserMenu) {
					<a
						class="menu__item"
						(mouseup)="goToPage('/enterprise', $event)"
					>
						<app-icon name="business"></app-icon>
						<span>{{ currentEnterprise.name }}</span>
					</a>

					<div
						class="line-notification menu__item"
						appStopPropagation
						(click)="openNotificationMenu()"
					>
						<app-icon name="notifications"></app-icon>
						<span>Уведомления</span>
						@if (notification && notification.length > 0) {
							<div class="g-badge g-badge-danger">
								{{ notification.length }}
							</div>
						}
					</div>

					@if (urlForResetPassword) {
						<a
							class="link menu__item"
							(mouseup)="goToSite(urlForResetPassword)"
						>
							<app-icon name="bi-key"></app-icon>
							<span>Сменить пароль</span>
						</a>
					}

				}


				<a class="menu__item"
				   (mouseup)="logout()"
				>
					<app-icon name="log-out-outline"></app-icon>
					<span>Выход</span>
				</a>
			</div>
		</mat-menu>
	</div>
} @else {
	<ul>
		<li>
			<a (click)="signin()"
			   title="Вход"
			   class="pointer"
			>
				<app-icon name="key"></app-icon>
			</a>
		</li>
	</ul>
}


@if (isOpenNotificationMenu) {
	<div
		class="backdrop"
		(click)="closeNotification()"
	></div>
	<app-notification
		@scaleAnimation
		class="dropdown_menu"
		[actualNotificationList]="notification"
		[archiveNotificationList]="archiveNotificationList"
		[userId]="userId"
		(onarchive)="onArchive($event)"
		(ondelete)="onDelete($event)"
		(onarchiveall)="onArchiveAll($event)"
		(ondeleteall)="onDeleteAll($event)"
	></app-notification>
}
