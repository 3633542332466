import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
	selector: 'app-tool-page',
	templateUrl: './tool-page.component.html',
	styleUrls: ['./tool-page.component.scss']
})
export class ToolPageComponent implements OnInit {
	toolId: number;

	constructor(
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		if (this.route.snapshot.params.id) {
			const toolId = Number(this.route.snapshot.params.id);

			if (toolId) {
				this.toolId = toolId;
			}
		}
	}

}
