import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IEventNotification, INotification} from './models/notification.types';
import {EListType} from './models/notification.enums';
import {MODALS} from '@shared/modals/modals';
import {ModalsService} from '@shared/services/modals.service';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
	@Input()
	actualNotificationList: INotification[] = [];

	@Input()
	archiveNotificationList: INotification[] = [];

	@Input()
	userId: number;

	@Output()
	ondelete = new EventEmitter<IEventNotification>();

	@Output()
	onarchive = new EventEmitter<INotification>();

	@Output()
	ondeleteall = new EventEmitter<INotification[]>();

	@Output()
	onarchiveall = new EventEmitter<INotification[]>();

	public listType = EListType.actual;
	public actual = EListType.actual;
	public archive = EListType.archive;

	constructor(
		private _modalService: ModalsService,
	) {
	}

	public showSubscriptionModal(): void {
		this._modalService.open(MODALS.UnitSubscriptionModal);
	}

	public setListType(): void {
		if (this.listType === EListType.actual) {
			this.listType = EListType.archive;
		} else if (this.listType === EListType.archive) {
			this.listType = EListType.actual;
		}
	}

	public onDelete(event: INotification, type: EListType): void {
		this.ondelete.emit({
			notification: event,
			type: type
		});
	}

	public onArchive(event): void {
		this.onarchive.emit(event);
	}

	public onDeleteAll(items): void {
		this.ondeleteall.emit(items);
	}

	public onArchiveAll(items): void {
		this.onarchiveall.emit(items);
	}
}
