import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {nextTick, sleep} from '@shared/utils';
import {setupThreeJs} from '@shared/components/easter-egg/services/init';
import {decoratorInvisible, getLight, getModel} from '@shared/components/easter-egg/services/utils';
import {animations} from '@shared/components/easter-egg/services/animation';


@Component({
	selector: 'app-easter-egg',
	templateUrl: './easter-egg.component.html',
	styleUrls: ['./easter-egg.component.scss']
})
export class EasterEggComponent implements OnInit {
	@Output() stop = new EventEmitter();

	isShow = true;

	ngOnInit() {
		nextTick().then(this.scriptEasterEgg.bind(this));
	}

	async scriptEasterEgg() {
		const {scene, clear} = setupThreeJs();
		const model = decoratorInvisible(await getModel());

		scene.add(
			getLight('front'),
			getLight('back'),
			model
		);

		animations.applyInfinityHalfRotate(model);

		await animations.applyAppear(model);
		await sleep(5000);
		await animations.applyDisappear(model);

		clear();

		this.isShow = false;
		this.stop.emit();
	}
}

