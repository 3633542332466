import {Component, Input, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';

import {SidebarService} from '../sidebar/services/sidebar.service';
import {RouterStateService} from '@shared/services/router-state.service';
import {SwitchThemeService} from '@shared/services/switch-theme.service';
import {untwistingAnimation} from './animations/untwisting.animation';

@UntilDestroy()
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [
		untwistingAnimation
	],
})
export class HeaderComponent implements OnInit {
	@Input() currentEnterprise;

	private _isSidebarShown: boolean;

	public currentComponentType: string;
	public isLightTheme$: Observable<boolean>;

	constructor(
		private sidebarService: SidebarService,
		private routerStateService: RouterStateService,
		private switchThemeService: SwitchThemeService,
	) {
		this.currentComponentType = '';
	}

	public ngOnInit(): void {
		this.isLightTheme$ = this.switchThemeService.isLightThemeListener();

		this.sidebarService.isSidebarShown
			.pipe(untilDestroyed(this))
			.subscribe((response: boolean): void => {
				this._isSidebarShown = response;
			});

		this.routerStateService
			.getCurrentRoute()
			.pipe(untilDestroyed(this))
			.subscribe(route => {
				this.currentComponentType = route.componentType;
			});
	}

	public toggleSidebar(): void {
		this.sidebarService.isSidebarShown.next(!this._isSidebarShown);
	}

	public switchTheme(): void {
		this.switchThemeService.switchTheme();
	}
}
