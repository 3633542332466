import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import {MatMenuTrigger} from '@angular/material/menu';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Subscription, timer} from 'rxjs';
import {filter} from 'rxjs/operators';

import {EFunctionalConstantEnums} from '@shared/constants/e-functional-constant.enums';
import {ConfigService} from '@shared/services/config.service';
import {EWebsocketEvents, WebsocketService} from '@shared/services/data.service';
import {MODALS} from '@shared/modals/modals';
import {AuthService} from '../../modules/auth/services/auth.service';
import {IEventNotification, INotification} from '../notification/models/notification.types';
import {cloneDeep} from 'lodash';
import {scaleAnimation} from './animations/scale.animation';
import {EListType} from '../notification/models/notification.enums';
import {ModalsService} from '@shared/services/modals.service';
import {AccessSelector} from '@store/accesses-store/access.selector';

@UntilDestroy()
@Component({
	selector: 'app-user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss'],
	animations: [
		scaleAnimation
	],
})
export class UserMenuComponent implements OnInit, OnDestroy {
	@Input() currentEnterprise;
	@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

	public isLoggedIn: boolean;
	public isOpenUserMenu: boolean;
	public isOpenNotificationMenu: boolean;
	public currentUser: string;
	public userName: string;
	public hasAccessToAdmin: boolean;
	public isFullUserMenu: boolean;
	public userImage: string;
	public userId: number;
	public notification: INotification[];
	public archiveNotificationList: INotification[];
	public urlForResetPassword?: string;

	private _isLoggedIn$: Subscription;
	private _isSubscribe: boolean;
	private _buttonDeleteIsPressed: boolean;
	private _maxMessageCount: number;

	constructor(
		private _authService: AuthService,
		private _router: Router,
		private _config: ConfigService,
		private _dataService: WebsocketService,
		private _modalDialog: ModalsService,
		private _accessSelector: AccessSelector,
	) {
		this.initProperties();
	}

	private initProperties(): void {
		this.isLoggedIn = false;
		this.isOpenUserMenu = false;
		this.isOpenNotificationMenu = false;
		this.hasAccessToAdmin = false;
		this.isFullUserMenu = false;
		this._isSubscribe = false;
		this._buttonDeleteIsPressed = false;
		this._maxMessageCount = 100;
	}

	public ngOnInit(): void {
		this._authService
			.getLoginStatus()
			.pipe(untilDestroyed(this))
			.subscribe(
				res => {
					this.isLoggedIn = res;

					if (res) {
						const config = this._config.getConfig([
							'javaApiUrl',
							'resetPassURL',
						]);

						this.currentUser = JSON.parse(
							localStorage.getItem('currentUser')
						);
						this.userName = this.currentUser['fullName'];
						this.userImage = config.javaApiUrl;
						this.userImage += this.currentUser['image'];
						this.userId = this.currentUser['userId'];
						this.urlForResetPassword = config.resetPassURL;
					} else {
						this._authService.logout();
						this._router.navigateByUrl('login');
					}
				},
				error => {
					this._authService.logout();
					this._router.navigateByUrl('login');
				}
			);

		this._router.events
			.pipe(
				untilDestroyed(this),
				filter(event => event instanceof ActivationEnd),
			)
			.subscribe((event: ActivationEnd): void => {
				this.isOpenUserMenu = false;
				this.isOpenNotificationMenu = false;
			});

		this.getArchiveNotificationList();

		this.hasAccessToAdmin =
			this._accessSelector.isAnyFunctionalAvailable([
				EFunctionalConstantEnums.admin,
				EFunctionalConstantEnums.manager
			]);


		this.isFullUserMenu =
			this._accessSelector.isAnyFunctionalAvailable([
				EFunctionalConstantEnums.user_menu,
			]);

		timer(1000)
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this._dataService
					.send<
						{ id: number },
						INotification[]
					>('subscription/subscribeOnUserMessage', {id: this.userId})
					.then(data => {
						this.notification = data;

						Array.prototype.forEach.call(
							this.notification,
							element => {
								element.date = this.createDate(
									element.date
								);
							}
						);

						this._isSubscribe = true;

						this._dataService
							.send(
								'subscription/getMaxMessageCount',
								{id: this.userId}
							)
							.then(data1 => {
								const dat = '' + data1;
								this._maxMessageCount =
									Number.parseInt(dat.toString());
							});

						this._dataService.messages[EWebsocketEvents.ON_USER_NOTIFICATION_DATA]
							.pipe(untilDestroyed(this))
							.subscribe(data => {
								data.date = this.createDate(
									data.date
								);

								if (
									Notification.permission !==
									'granted'
								) {
									Notification.requestPermission();
								}

								this.notification.unshift(data);

								// tslint:disable-next-line:max-line-length
								// Проверяем не вышли ли мы за диапазон допустимого колличества сообщений
								if (
									this.notification.length >
									this._maxMessageCount
								) {
									this.notification.shift();
								}
							});
					});
			});
	}

	private createDate(date: Date): string {
		return new Date(date).toLocaleString('ru').toString();
	}

	public closeNotification(): void {
		this.isOpenNotificationMenu = false;
	}

	public openQrModal(): void {
		this._modalDialog.open(MODALS.QRCodeScanner);
	}

	private getArchiveNotificationList(): void {
		this._dataService
			.send<
				{ id: number },
				INotification[]
			>('subscription/getAllArchiveUserMessage', {id: this.userId})
			.then(data => {
				this.archiveNotificationList = data;
				this.archiveNotificationList.forEach(element => {
					element.date = this.createDate(element.date as Date);
				});
			});
	}

	public ngOnDestroy(): void {
		if (this._isLoggedIn$) {
			this._isLoggedIn$.unsubscribe();
		}

		this._dataService.send('subscription/unSubscribeOnUserMessage', {
			id: this.userId,
		});
	}

	public toggleUserMenu(): void {
		this.isOpenUserMenu = !this.isOpenUserMenu;
		this.isOpenNotificationMenu = false;
	}

	public logout(): void {
		this._authService.logout();
	}

	public goToPage(route: string, event: MouseEvent): void {
		if (event.button === 0) {
			this._router.navigateByUrl(route);
		} else if (event.button === 1) {
			window.open(route);
		}
	}

	public goToSite(route: string): void {
		window.open(route);
	}

	public signin(): void {
		this._router.navigate(['login']);
	}

	public openNotificationMenu(): void {
		this.isOpenNotificationMenu = true;
		this.isOpenUserMenu = false;

		this.trigger.closeMenu();
	}

	public onDelete(event: IEventNotification): void {
		this._dataService.send('subscription/deleteUserMessage', {
			id: event.notification.subscriptionMessageHistoryId,
		});

		if (event.type === EListType.actual) {
			this.notification = this.deleteFromNotificationList(
				event.notification,
				this.notification
			);
		} else {
			this.archiveNotificationList = this.deleteFromNotificationList(
				event.notification,
				this.archiveNotificationList
			);
		}
	}

	public onArchive(event: INotification): void {
		this._dataService
			.send('subscription/archiveUserMessage', {
				id: event.subscriptionMessageHistoryId,
			})
			.finally(() => {
				this.getArchiveNotificationList();
			});

		this.notification = this.deleteFromNotificationList(
			event,
			this.notification
		);
	}

	public onDeleteAll(items: INotification[]): void {
		const ids = items.map(not => not.subscriptionMessageHistoryId);

		this._dataService.send('subscription/deleteAllUserMessage', ids);

		this.deleteFromNotificationListAll(ids);
	}

	public onArchiveAll(items: INotification[]): void {
		const ids = items.map(not => not.subscriptionMessageHistoryId);

		this._dataService
			.send('subscription/archiveAllUserMessage', ids)
			.finally(() => {
				this.getArchiveNotificationList();
			});

		this.deleteFromNotificationListAll(ids);
	}

	private deleteFromNotificationList(
		event: INotification,
		notification: INotification[]
	): INotification[] {
		this._buttonDeleteIsPressed = true;

		const indexElement = notification.findIndex(
			item =>
				item.subscriptionMessageHistoryId ===
				event.subscriptionMessageHistoryId
		);

		if (indexElement !== -1) {
			notification = cloneDeep(
				notification.filter((item, index) => index !== indexElement)
			);
		}

		return notification;
	}

	private deleteFromNotificationListAll(ids: number[]): void {
		this._buttonDeleteIsPressed = true;

		for (let i = 0; i < this.notification.length; i++) {
			if (
				ids.includes(this.notification[i].subscriptionMessageHistoryId)
			) {
				this.notification = [];
			}
		}
	}
}
