import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Router} from '@angular/router';
import {INotification} from '../../models/notification.types';
import {EListType} from '../../models/notification.enums';

@Component({
	selector: 'app-notification-list',
	templateUrl: './notification-list.component.html',
	styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnChanges {
	@Input() listType: EListType;
	@Input() currentListType: EListType;
	@Input() notificationList: INotification[];
	@Output() ondelete = new EventEmitter<INotification>();
	@Output() onarchive = new EventEmitter<INotification>();
	@Output() ondeleteall = new EventEmitter<INotification[]>();
	@Output() onarchiveall = new EventEmitter<INotification[]>();
	@Input() userId: number;

	public EListType = EListType;

	private _filesNames: Map<number, Map<string, string>>;

	constructor(
		private router: Router,
	) {
		this._filesNames = new Map();
	}

	public ngOnChanges(): void {
		this.notificationList?.forEach((notification: INotification) => {
			if (!!notification.fileList?.length) {
				const files = new Map();
				notification.fileList.forEach((file: string) => {
					files.set(file, this.getNameByPath(file));
				});
				this._filesNames.set(notification.subscriptionMessageHistoryId, files);
			}
		});
	}

	public readNotification(item: INotification): void {
		this.onarchive.emit(item);
	}

	public deleteNotification(item: INotification): void {
		this.ondelete.emit(item);

		if (EListType.archive === this.currentListType) {
			for (let i = 0; i < this.notificationList.length; i++) {
				const selectedId = item.subscriptionMessageHistoryId;
				const currentId = this.notificationList[i].subscriptionMessageHistoryId;

				if (selectedId === currentId) {
					this.notificationList.splice(i, 1);
				}
			}
		}
	}

	public readAllNotifications(): void {
		this.onarchiveall.emit(this.notificationList);

		this.notificationList = [];
	}

	public deleteAllNotifications(): void {
		this.ondeleteall.emit(this.notificationList);

		this.notificationList = [];
	}

	public getFileName(id: number, filePath: string): string | undefined {
		return this._filesNames.get(id)?.get(filePath);
	}

	private getNameByPath(path: string): string {
		let lastSeparator = 0;

		for (let i = 0; i < path.length; i++) {
			if (path[i] === '/') {
				lastSeparator = i;
			}
		}

		return path.slice(lastSeparator + 1, path.length);
	}

	public goToTask(taskId: number, event: MouseEvent): void {
		this.goToPage('/service-desk/task-list/task/' + taskId, event);
	}

	public goToUnit(unitId: number, event: MouseEvent): void {
		this.goToPage('/monitoring/unit/' + unitId, event);
	}

	private goToPage(route: string, event: MouseEvent): void {
		if (event.button === 0) {
			this.router.navigateByUrl(route);
		} else if (event.button === 1) {
			window.open(route);
		}
	}
}
