import {NgModule} from '@angular/core';
import {ConnectionLossModalComponent} from './connection-loss-modal.component';
import {CommonModule} from '@angular/common';
import {ButtonComponent} from '@shared/ui-components/button/button.component';

@NgModule({
	declarations: [ConnectionLossModalComponent,],
	exports: [ConnectionLossModalComponent,],
	imports: [
		CommonModule,
		ButtonComponent,
	],
})
export class ConnectionLossModalModule {
}
