import { __decorate, __param, __metadata } from 'tslib';
import { InjectionToken, NgZone, Inject, ɵɵdefineInjectable, ɵɵinject, Injectable, APP_INITIALIZER, NgModule } from '@angular/core';
import { akitaDevtools } from '@datorama/akita';

var AkitaNgDevtools_1;
const DEVTOOLS_OPTIONS = new InjectionToken('DevtoolsOptions');
let AkitaDevtools = class AkitaDevtools {
    constructor(ngZone, options) {
        this.ngZone = ngZone;
        this.options = options;
        akitaDevtools(this.ngZone, this.options);
    }
};
AkitaDevtools.ctorParameters = () => [
    { type: NgZone },
    { type: undefined, decorators: [{ type: Inject, args: [DEVTOOLS_OPTIONS,] }] }
];
AkitaDevtools.ɵprov = ɵɵdefineInjectable({ factory: function AkitaDevtools_Factory() { return new AkitaDevtools(ɵɵinject(NgZone), ɵɵinject(DEVTOOLS_OPTIONS)); }, token: AkitaDevtools, providedIn: "root" });
AkitaDevtools = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __param(1, Inject(DEVTOOLS_OPTIONS)),
    __metadata("design:paramtypes", [NgZone, Object])
], AkitaDevtools);
// auto initialize the devtools
function d() { }
function init(akitaDevtools) {
    return d;
}
let AkitaNgDevtools = AkitaNgDevtools_1 = class AkitaNgDevtools {
    static forRoot(options) {
        return {
            ngModule: AkitaNgDevtools_1,
            providers: [
                {
                    provide: DEVTOOLS_OPTIONS,
                    useValue: options
                },
                {
                    provide: APP_INITIALIZER,
                    useFactory: init,
                    deps: [AkitaDevtools],
                    multi: true
                }
            ]
        };
    }
};
AkitaNgDevtools = AkitaNgDevtools_1 = __decorate([
    NgModule({})
], AkitaNgDevtools);

/**
 * Generated bundle index. Do not edit.
 */

export { AkitaDevtools, AkitaNgDevtools, DEVTOOLS_OPTIONS, d, init };

