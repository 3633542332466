import {animate, style, transition, trigger} from '@angular/animations';

export const untwistingAnimation = trigger('untwistingAnimation', [
	transition('void => *', [
		style({transform: 'translateY(-35px)'}),
		animate('.25s ease-in-out', style({transform: 'translateY(0px)'})),
	]),
	transition('* => void', [
		style({transform: 'translateY(0px)'}),
		animate('.25s ease-in-out', style({transform: 'translateY(35px)'})),
	]),
]);
