import {RouterModule, Routes} from '@angular/router';
import {ToolPageComponent} from '@shared/components/tool-page/tool-page.component';
import {UserGuard} from '@shared/guards/user-guard';
import {AdminGuard} from '@shared/guards/admin-guard';
import {EnterpriseComponent} from './modules/enterprise/enterprise.component';
import {NgModule} from '@angular/core';
import {URL_START_PAGE} from './app.component';
import {NavigationGuard} from '@shared/guards/navigation-guard';
import {AboutComponent} from './modules/monitoring/components/about/about.component';

const routes: Routes = [
	{
		path: 'tool/:id',
		component: ToolPageComponent,
		canActivate: [UserGuard],
		data: {
			title: 'Инструмент',
			breadcrumb: 'Инструмент',
		},
	},
	{
		path: 'idle',
		loadChildren: () =>
			import('./modules/idles/idles.module').then(x => x.IdlesModule),
		canActivate: [UserGuard, NavigationGuard],
		data: {
			title: 'Простои',
			breadcrumb: 'Простои',
		},
	},

	{
		path: 'adm',
		loadChildren: () =>
			import('./modules/admin/admin.module').then(x => x.AdminModule),
		data: {
			title: 'Административная панель',
			breadcrumb: 'Административная панель',
		},
		canActivate: [AdminGuard],
	},
	{
		path: 'monitoring',
		loadChildren: () =>
			import('./modules/monitoring/monitoring.module').then(
				x => x.MonitoringModule
			),
		canActivate: [UserGuard, NavigationGuard],
		data: {
			title: 'Мониторинг',
			breadcrumb: 'Мониторинг',
		},
	},
	{
		path: 'docs',
		loadChildren: () =>
			import('./modules/documentation/documentation.module').then(
				x => x.DocumentationModule
			),
		canActivate: [UserGuard, NavigationGuard],
		data: {
			title: 'Документация',
			breadcrumb: 'Документация',
		},
	},
	{
		path: 'login',
		loadChildren: () =>
			import('./modules/auth/auth.module').then(x => x.AuthModule),
		data: {
			title: 'Auth',
			breadcrumb: 'Аутентификация',
		},
	},
	{
		path: 'stat',
		loadChildren: () =>
			import('./modules/statistic/statistic.module').then(
				x => x.StatisticModule
			),
		canActivate: [UserGuard, NavigationGuard],
		data: {
			title: 'Статистика',
			breadcrumb: 'Статистика',
		},
	},
	{
		path: 'dashboard',
		loadChildren: () =>
			import('./modules/dashboard/dashboard.module').then(
				x => x.DashboardModule
			),
		data: {
			title: 'Дашборд',
			breadcrumb: 'Дашборд',
		},
	},
	{
		path: 'errors',
		loadChildren: () =>
			import('./error-pages/http-errors.module').then(
				x => x.HttpErrorsModule
			),
		canActivate: [UserGuard],
		data: {
			title: 'Ошибка',
			breadcrumb: 'Ошибка',
		},
	},
	{
		path: 'resources',
		loadChildren: () =>
			import('./modules/energy-resources/energy-resources.module').then(
				x => x.EnergyResourcesModule
			),
		canActivate: [UserGuard, NavigationGuard],
		data: {
			title: 'Энергоресурсы',
			breadcrumb: 'Энергоресурсы',
		},
	},
	{
		path: 'enterprise',
		component: EnterpriseComponent,
		canActivate: [UserGuard, NavigationGuard],
		data: {
			title: 'Выбор предприятия',
			breadcrumb: 'Выбор предприятия',
		},
	},
	{
		path: 'personal-area',
		loadChildren: () =>
			import('./modules/personal-area/personal-area.module').then(
				x => x.PersonalAreaModule
			),
		canActivate: [UserGuard],
		data: {
			title: 'Личный кабинет',
			breadcrumb: 'Личный кабинет',
		},
	},
	{
		path: 'about',
		component: AboutComponent,
		canActivate: [UserGuard, NavigationGuard],
		data: {
			title: 'О системе',
			breadcrumb: 'О системе',
		},
	},
	{
		path: 'service-desk',
		loadChildren: () =>
			import('./modules/sd/sd.module').then(x => x.SdModule),
		canActivate: [UserGuard, NavigationGuard],
		data: {
			title: 'Service desk',
			breadcrumb: 'SD',
		},
	},
	{
		path: 'task',
		loadChildren: () =>
			import('./modules/task/task.module').then(x => x.TaskModule),
		canActivate: [UserGuard, NavigationGuard],
		data: {
			title: 'Задача',
			breadcrumb: 'Задача',
		},
	},
	{
		path: 'planning',
		loadChildren: () =>
			import('./modules/plan/plan.module').then(x => x.PlanModule),
		canActivate: [UserGuard, NavigationGuard],
		data: {
			title: 'Планирование',
			breadcrumb: 'Планирование',
		},
	},
	{
		path: 'nomenclature-warehouse',
		loadChildren: () =>
			import('./modules/nomenclature-store/nomenclature.module').then(
				x => x.NomenclatureModule
			),
		canActivate: [UserGuard, NavigationGuard],
		data: {
			title: 'Номенклатура',
			breadcrumb: 'Номенклатура',
		},
	},
	{
		path: '',
		redirectTo: URL_START_PAGE,
		pathMatch: 'full',
	},
	{
		path: '**',
		redirectTo: '',
		data: {
			title: 'Главная',
		},
		canActivate: [UserGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)]
})
export class AppRouting {
}

