import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { MainNavigationService } from '../user-menu/services/main-navigation.service';
import { SidebarService } from './services/sidebar.service';
import { untwistingAnimation } from '../../shared/animations/common.animations';
import { INavigationMenu } from '../user-menu/models/main-navigation.types';
import { Platform } from '@angular/cdk/platform';
import { SidebarType } from './models/sidebar.types';

@UntilDestroy()
@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [untwistingAnimation],
})
export class SidebarComponent implements OnInit {
	public isSidebarShown: boolean;
	public menu: INavigationMenu[];
	public activeRouteIndex: number;
	public activeCollapsibleItemRoute: string;

	private _activeMenuItemIndex: number;
	private _counterTimerForAppearEasterEgg: number;

	constructor(
		public sidebarService: SidebarService,
		public platform: Platform,
		private _navService: MainNavigationService,
		private _router: Router,
		private _elementRef: ElementRef,
		private _changeDetectorRef: ChangeDetectorRef,
	) {
		this.activeRouteIndex = 1;
		this.activeCollapsibleItemRoute = 'monitoring/map';
		this._counterTimerForAppearEasterEgg = 0;
	}

	get isShowEasterEgg(): boolean {
		return this._counterTimerForAppearEasterEgg > 3;
	}

	public ngOnInit(): void {
		setInterval(() => {
			if (this._counterTimerForAppearEasterEgg <= 0) {
				return;
			}
			this._counterTimerForAppearEasterEgg--;
		}, 500);

		this.sidebarService.typeSidebar
			.pipe(
				untilDestroyed(this),
				filter((value: SidebarType) => value === 'terminal'),
			)
			.subscribe(() => {
				this._elementRef.nativeElement.parentNode.classList.add(
					'terminal',
				);
			});

		this.sidebarService.isSidebarShown
			.pipe(untilDestroyed(this))
			.subscribe(async (response: boolean) => {
				this.isSidebarShown = response;

				if (!this.menu) {
					this.menu = await this._navService.getNav();
					this.definitionCurrentItemMenu(this._router.url);
					this._changeDetectorRef.markForCheck();
				} else if (!response) {
					for (let i = 0; i < this.menu.length; i++) {
						this.menu[i].iconState = 'default';
						this.menu[i].accordionState = 'initial';
					}
				} else if (response && !!this.menu[this._activeMenuItemIndex]) {
					this.menu[this._activeMenuItemIndex].iconState = 'rotated';
					this.menu[this._activeMenuItemIndex].accordionState =
						'expanded';
				}
			});

		this._router.events
			.pipe(untilDestroyed(this))
			.subscribe(event => {
				if (event instanceof NavigationEnd) {
					this.definitionCurrentItemMenu(event.url);
					this._changeDetectorRef.detectChanges();
				}
			});
	}

	private definitionCurrentItemMenu(url: string): void {
		for (let i = 0; i < this.menu.length; i++) {
			if (this.menu[i].route === url) {
				this.setActiveMenu(i, url);

				return;
			} else if (!!this.menu[i].children) {
				for (let a = 0; a < this.menu[i].children.length; a++) {
					if (url.indexOf(this.menu[i].children[a].route) !== -1) {
						this.setActiveMenu(i, this.menu[i].children[a].route);

						return;
					}
				}
			}
		}

		this.setActiveMenu(null, null);
	}

	public collapse(menuItem: INavigationMenu, index: number): void {
		for (let i = 0; i < this.menu.length; i++) {
			if (i !== index) {
				this.menu[i].iconState = 'default';
				this.menu[i].accordionState = 'initial';
			}
		}

		menuItem.iconState =
			menuItem.iconState === 'default' ? 'rotated' : 'default';
		menuItem.accordionState =
			menuItem.accordionState === 'initial' ? 'expanded' : 'initial';
	}

	public mouseEnter(): void {
		if (this.isSidebarShown || !this.menu?.[this._activeMenuItemIndex]) {
			return;
		}

		this.menu[this._activeMenuItemIndex].accordionState = 'expanded';
		this.menu[this._activeMenuItemIndex].iconState = 'rotated';
	}

	public mouseLeave(): void {
		if (this.sidebarService.isSidebarShown.value) {
			return;
		}

		for (let i = 0; i < this.menu?.length; i++) {
			this.menu[i].iconState = 'default';
			this.menu[i].accordionState = 'initial';
		}
	}

	public goToPage(route: string, event): void {
		if (event.button === 0) {
			this._router.navigateByUrl(route);
		} else if (event.button === 1) {
			window.open(route);
		}
	}

	private setActiveMenu(i: number, url: string): void {
		this.initActiveItem(i, url);

		if (
			this.isSidebarShown &&
			this.menu[i] &&
			!this.sidebarService.isSidebarShown.value
		) {
			this.menu[i].accordionState = 'initial';
			this.menu[i].iconState = 'default';
			this.collapse(this.menu[i], i);
		}
	}

	private initActiveItem(i: number, route: string): void {
		this.activeRouteIndex = i;
		this._activeMenuItemIndex = i;
		this.activeCollapsibleItemRoute = route;
	}

	public toggleSidebar(): void {
		this.increaseCounterEasterEgg();
		this.sidebarService.isSidebarShown.next(!this.isSidebarShown);
	}

	private increaseCounterEasterEgg(): void {
		if (this._counterTimerForAppearEasterEgg++ === 3) {
			this._counterTimerForAppearEasterEgg = 100;
		}
	}

	public clearCounterEasterEgg(): void {
		this._counterTimerForAppearEasterEgg = 0;
	}
}
