import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

import {ConfigService} from '@shared/services/config.service';

@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.scss'],
	animations: [
		trigger('animateFadeIn', [
			state(
				'show',
				style({
					opacity: '1'
				})
			),
			transition('hide <=> show', [
				animate('1s ease-out')
			]),
		])
	]
})
export class AboutComponent implements OnInit {
	showContent = false;
	showDev = false;
	developers = [
		{
			name: 'Агафонов Никита Александрович',
			photo: '13732_Агафонов_Никита_Александрович.jpg'
		},
		{
			name: 'Алексеев Антон Юрьевич',
			photo: '12810_Алексеев_Антон_Юрьевич.jpg'
		},
		{
			name: 'Баранов Владислав Валерьевич',
			photo: '22508_Баранов_Владислав_Валерьевич.jpg'
		},
		{
			name: 'Башенин Александр Олегович',
			photo: '22009_Башенин_Александр_Олегович.jpg'
		},
		{
			name: 'Бодалев Александр Николаевич',
			photo: '21606_Бодалев_Александр_Николаевич.jpg'
		},
		{
			name: 'Герман Александр Валерьевич',
			photo: '12528_Герман_Александр_Валериевич.jpg'
		},
		{
			name: 'Долженко Дмитрий Сергеевич',
			photo: '23402_Долженко_Дмитрий_Сергеевич.jpg'
		},
		{
			name: 'Ившин Алексей Николаевич',
			photo: '13248_Ившин_Алексей_Николаевич.jpg'
		},
		{
			name: 'Карга Сергей Павлович',
			photo: '19954_Карга_Сергей_Павлович.jpg'
		},
		{
			name: 'Киселев Андрей Валерьевич',
			photo: '20059_Киселев_Андрей_Валерьевич.jpg'
		},
		{
			name: 'Комаров Артём Александрович',
			photo: 'Комаров_Артём_Александрович.jpg'
		},
		{
			name: 'Кузнецов Денис Сергеевич',
			photo: '12966_Кузнецов_Денис_Сергеевич.jpg'
		},
		{
			name: 'Кулаков Олег Алексеевич',
			photo: '19972_Кулаков_Олег_Алексеевич.jpg'
		},
		{
			name: 'Лукманов Ринат Ирикович',
			photo: '21136_Лукманов_Ринат_Ирикович.jpg'
		},
		{
			name: 'Майоров Михаил Николаевич',
			photo: '19668_Майоров_Михаил_Николаевич.jpg'
		},
		{
			name: 'Нечеухин Александр Викторович',
			photo: '21462_Нечеухин_Александр_Викторович.jpg'
		},
		{
			name: 'Решетников Владимир Викторович',
			photo: '13631_Решетников_Владимир_Викторович.jpg'
		},
		{
			name: 'Фролов Григорий Васильевич',
			photo: '23505_Фролов_Григорий_Васильевич.jpg'
		},
		{
			name: 'Функ Артур Рустэмович',
			photo: '19970_Функ_Артур_Рустэмович.jpg'
		},
		{
			name: 'Шалавин Андрей Александрович',
			photo: '17483_Шалавин_Андрей_Александрович.jpg'
		},
		{
			name: 'Янбухтин Александр Ринатович',
			photo: '12614_Янбухтин_Александр_Ринатович.jpg'
		},
		{
			name: 'Онофрийчук Марат Петрович',
			photo: '12614_Янбухтин_Александр_Ринатович.jpg'
		},
		{
			name: 'Онофрийчук Тимур Петрович',
			photo: '12614_Янбухтин_Александр_Ринатович.jpg'
		},
		{
			name: 'Калинин Евгений Андреевич',
			photo: '12614_Янбухтин_Александр_Ринатович.jpg'
		}
	];
	devStr = '';
	instructionPath = '';

	constructor(
		private _config: ConfigService,
	) {
	}

	public ngOnInit(): void {
		this.instructionPath = this._config.getConfig(['userGuideUrl']).userGuideUrl;
		this.devStr = this.developers.reduce((acc, item) => acc + item.name + ', ', '').slice(0, -2);

		setTimeout(() => this.showContent = true, 300);
		setTimeout(() => this.showDev = true, 900);
	}
}
