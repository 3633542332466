<app-button
	class="g-button-secondary-square"
	svgIcon="notifications"
	(click)="showSubscriptionModal()"
>
	Общие уведомления
</app-button>

<mat-tab-group
	animationDuration="0ms"
	class="full-width full-height"
	(selectedIndexChange)="setListType()"
>
	<mat-tab label="Текущие">
		<app-notification-list
			(onarchive)="onArchive($event)"
			(ondelete)="onDelete($event, actual)"
			(onarchiveall)="onArchiveAll($event)"
			(ondeleteall)="onDeleteAll($event)"
			[listType]="actual"
			[currentListType]="listType"
			[notificationList]="actualNotificationList"
			[userId]="userId"
		></app-notification-list>
	</mat-tab>

	<mat-tab label="Архивные">
		<app-notification-list
			(ondelete)="onDelete($event, archive)"
			(onarchiveall)="onArchiveAll($event)"
			(ondeleteall)="onDeleteAll($event)"
			[listType]="archive"
			[currentListType]="listType"
			[notificationList]="archiveNotificationList"
			[userId]="userId"
		></app-notification-list>
	</mat-tab>
</mat-tab-group>
