import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BreadcrumbsService } from './services/breadcrumbs.service';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-breadcrumbs',
	templateUrl: 'breadcrumbs.component.html',
	styleUrls: ['breadcrumbs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit {
	constructor(
		public breadcrumbsService: BreadcrumbsService,
		private _router: Router,
		private _cdr: ChangeDetectorRef,
	) {
	}

	public ngOnInit(): void {
		this.initUpdatingPage();
		this.initBreadcrumbs();
	}

	private initUpdatingPage(): void {
		this.breadcrumbsService.breadcrumbs
			.pipe(untilDestroyed(this))
			.subscribe(() => this._cdr.detectChanges());
	}

	private initBreadcrumbs(): void {
		this.breadcrumbsService.generateBreadcrumbs();

		this._router.events
			.pipe(
				untilDestroyed(this),
				filter((event) => event instanceof NavigationEnd),
			).subscribe(() => this.breadcrumbsService.generateBreadcrumbs());
	}
}
