import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EFunctionalConstantEnums } from '@shared/constants/e-functional-constant.enums';
import { firstValueFrom } from 'rxjs';
import { AccessSelector } from '@store/accesses-store/access.selector';

@Injectable({
	providedIn: 'root'
})
export class AdminGuard implements CanActivate {
	constructor(
		private _notifierService: NotifierService,
		private _loaderService: NgxUiLoaderService,
		private _accessSelector: AccessSelector
	) {
	}

	async canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> {
		this._loaderService.start();

		let permissions = await firstValueFrom(this._accessSelector.getFilledPermissions$());

		const isHaveAccess = this._accessSelector.isAnyFunctionalAvailable(
			[EFunctionalConstantEnums.admin, EFunctionalConstantEnums.manager],
			permissions.functional
		);

		const message = isHaveAccess
			? ['success', 'Доступны права администратора']
			: ['error', 'Нет прав администратора'];

		// @ts-ignore
		this._notifierService.notify(...message);

		this._loaderService.stop();

		return isHaveAccess;
	}
}
