import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconModule } from '@shared/ui-components/icon/icon.module';
import { NgClass, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	standalone: true,
	imports: [
		FormsModule,
		IconModule,
		NgClass,
		NgIf,
		MatIconModule,
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputComponent),
			multi: true,
		},
	],
})

/*
	Внимательно к ознакомлению: Если нужен input без валидации - при использовании app-input validate не указывать!!!
*/
export class InputComponent implements ControlValueAccessor {
	@Input() placeholder?: string;
	@Input() disabled?: boolean;
	@Input() validate?: boolean = false;
	@Input() svgIcon?: string;
	@Input() required?: boolean;
	@Input() type?: string = 'text';
	@Input() control?: AbstractControl;

	private value: string = '';

	private onChange: (value: string) => void = (): void => {
	};
	protected onTouched: () => void = (): void => {
	};

	writeValue(value: string): void {
		this.value = value;
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	onInput(event: Event): void {
		const target = event.target as HTMLInputElement;
		this.value = target.value;
		this.onChange(this.value);
	}

	isValid(): boolean {
		return this.control ? this.control.valid && (this.control.touched || this.control.dirty) : false;
	}

	isInvalid(): boolean {
		return this.control ? this.control.invalid && (this.control.touched || this.control.dirty) : false;
	}
}
