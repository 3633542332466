<div class="side left">
	<app-button
		class="g-button-secondary-empty btn-toggle"
		svgIcon="menu"
		(click)="toggleSidebar()"
	>
	</app-button>

	@if (currentEnterprise) {
		<div
			class="g-breadcrumbs g-unselectable"
			[ngSwitch]="currentComponentType"
		>
			<app-breadcrumbs></app-breadcrumbs>
		</div>
	}
</div>

<div class="side right">
	<div class="toggle-theme">
		@if (isLightTheme$ | async) {
			<app-button
				@untwistingAnimation
				class="g-button-secondary-empty"
				svgIcon="sun"
				matTooltip="Переключить на темную тему"
				(click)="switchTheme()"
			>
			</app-button>
		} @else {
			<app-button
				@untwistingAnimation
				class="g-button-secondary-empty"
				svgIcon="moon"
				matTooltip="Переключить на светлую тему"
				(click)="switchTheme()"
			>
			</app-button>
		}
	</div>

	@if (currentEnterprise) {
		<app-user-menu [currentEnterprise]="currentEnterprise"></app-user-menu>
	}

</div>
