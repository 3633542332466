// @ts-ignore
import TWEEN from 'three/addons/libs/tween.module.js';

export const animations = {
	applyAppear,
	applyDisappear,
	applyInfinityHalfRotate,
};

function applyAppear(model, duration = 3000) {
	const newScale = {x: 0.017, y: 0.017, z: 0.017};

	return new Promise((res) => {
		new TWEEN.Tween(model.scale)
			.to(newScale, duration)
			.easing(TWEEN.Easing.Exponential.InOut)
			.start()
			.onComplete(res);
	});
};

function applyDisappear(model, duration = 3000) {
	return new Promise((res) => {
		new TWEEN.Tween(model.scale)
			.to({
				y: 0, x: 0, z: 0,
			}, duration)
			.easing(TWEEN.Easing.Exponential.InOut)
			.start()
			.onComplete(res);
	});
};

function applyInfinityHalfRotate(model, rotation = -0.5) {
	const during = Math.random() * 1000 + 3000;

	new TWEEN.Tween(model.rotation)
		.to({y: rotation}, during)
		.start()
		.onComplete(() => applyInfinityHalfRotate(model, rotation * -1));
};

