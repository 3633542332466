import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularSplitModule} from 'angular-split';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {NotifierModule} from 'angular-notifier';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {ruLocale} from 'ngx-bootstrap/locale';

import {AppComponent} from './app.component';
import {HeaderComponent} from './layout/header/header.component';
import {NotificationComponent} from './layout/notification/notification.component';
import {NotificationListComponent} from './layout/notification/components/notification-list/notification-list.component';
import {UserMenuComponent} from './layout/user-menu/user-menu.component';
import {RouterModule} from '@angular/router';
import {httpInterceptorProviders} from './shared/interceptors';
import {ngxUiLoaderConfig, notifierOptions} from './configs';
import {BreadcrumbsService} from './layout/breadcrumbs/services/breadcrumbs.service';
import {SharedModule} from '@shared/shared.module';
import {CookieService} from 'ngx-cookie-service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MatFormFieldModule} from '@angular/material/form-field';

import {RouterStateService} from '@shared/services/router-state.service';
import {HttpErrorsModule} from './error-pages/http-errors.module';
import {AdminGuard} from '@shared/guards/admin-guard';
import {UserGuard} from '@shared/guards/user-guard';
import {EnterpriseComponent} from './modules/enterprise/enterprise.component';
import {WebsocketService} from '@shared/services/data.service';
import {environment} from '../environments/environment';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import {BreadcrumbsComponent} from './layout/breadcrumbs/breadcrumbs.component';
import {MatIconModule} from '@angular/material/icon';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {IconModule} from '@shared/ui-components/icon/icon.module';
import {QrScannerModule} from '@shared/components/qr-scanner/qr-scanner.module';
import {ToolItemModule} from '@shared/components/tool-item/tool-item.module';
import {ColumnModule} from '@shared/ui-components/column/column.module';
import {NomenclatureRouting} from './modules/nomenclature-store/nomenclature.routing';
import {UseCustomIconService} from '@shared/services/use-custom-icon.service';
import {StopPropagationModule} from '@shared/directives/stop-propagation/stop-propagation.module';
import {FrostedGlassModule} from '@shared/ui-components/frosted-glass/frosted-glass.module';
import {AppRouting} from './app.routing';
import {TaskManager} from './shared/utils';
import {AdaptiveModule} from '@shared/directives/adaptive/adaptive.module';
import {EventBusService} from '@shared/utils/event-bus';
import {EasterEggModule} from '@shared/components/easter-egg/easter-egg.module';
import {ClickOnOtherElementsModule} from '@shared/directives/click-on-other-elements/click-on-other-elements.module';
import {MatTabsModule} from '@angular/material/tabs';
import {MatOptionModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {TableModule} from '@shared/ui-components/table/table.module';
import {NavigationGuard} from '@shared/guards/navigation-guard';
import {IntervalModule} from '@shared/ui-components/n-inverval/interval.module';
import {NotAccessForMobileModule} from '@shared/ui-components/not-access-for-mobile/not-access-for-mobile.module';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import {PopoverModule} from '@shared/directives/popover/popover.module';
import {ApiRepository} from '@shared/repositories/api.repository';
import {LoginComponent} from './modules/auth/components/login/login.component';
import {AuthModule} from './modules/auth/auth.module';
import {ConfirmPassComponent} from './modules/auth/components/login/components/confirm-pass/confirm-pass.component';
import {AboutComponent} from './modules/monitoring/components/about/about.component';
import {InputComponent} from '@shared/ui-components/input/input.component';
import {PaginationComponent} from '@shared/ui-components/paginator/pagination.component';
import {EnlargedImageModalModule} from '@shared/modals/enlarged-image-modal/enlarged-image-modal.module';
import {ConnectionLossModalModule} from '@shared/modals/connection-loss/connection-loss-modal.module';
import {UnitSubscriptionComponent} from '@shared/components/unit-subscription/unit-subscription.component';
import {UnitSubscriptionModalComponent} from '@shared/modals/unit-subscription/unit-subscription-modal.component';

registerLocaleData(localeRu);

defineLocale('ru', ruLocale);

@NgModule({
	declarations: [
		AppComponent,
		SidebarComponent,
		HeaderComponent,
		NotificationComponent,
		UserMenuComponent,
		BreadcrumbsComponent,
		EnterpriseComponent,
		AboutComponent,
		LoginComponent,
		ConfirmPassComponent,
		NotificationListComponent,
	],
	imports: [
		HammerModule,
		AngularSplitModule,
		BrowserModule,
		BrowserAnimationsModule,
		environment.production ? [] : AkitaNgDevtools.forRoot(),
		HttpClientModule,
		FormsModule,
		AuthModule,
		HttpErrorsModule,
		AppRouting,
		NotifierModule.withConfig({
			...notifierOptions,
		}),
		ReactiveFormsModule.withConfig({
			warnOnNgModelWithFormControl: 'never',
		}),
		NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
		SharedModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			registrationStrategy: 'registerWhenStable:30000',
		}),
		MatFormFieldModule,
		MatIconModule,
		EnlargedImageModalModule,
		IconModule,
		QrScannerModule,
		ToolItemModule,
		ColumnModule,
		NomenclatureRouting,
		NotAccessForMobileModule,
		MatButtonModule,
		MatTooltipModule,
		MatMenuModule,
		StopPropagationModule,
		MatSlideToggleModule,
		FrostedGlassModule,
		AdaptiveModule,
		ClickOnOtherElementsModule,
		EasterEggModule,
		ConnectionLossModalModule,
		MatTabsModule,
		TableModule,
		MatAutocompleteModule,
		MatCheckboxModule,
		MatInputModule,
		MatOptionModule,
		IntervalModule,
		ButtonComponent,
		PopoverModule,
		UnitSubscriptionComponent,
		UnitSubscriptionModalComponent,
		InputComponent,
		PaginationComponent,
	],
	exports: [RouterModule],
	providers: [
		NavigationGuard,
		AdminGuard,
		UserGuard,
		CookieService,
		BreadcrumbsService,
		ApiRepository,
		RouterStateService,
		WebsocketService,
		UseCustomIconService,
		TaskManager,
		EventBusService,
		httpInterceptorProviders,
		{provide: LOCALE_ID, useValue: 'ru'},
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
