import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import { ConfigService, IConfigEnterprise } from '@shared/services/config.service';

@Component({
	selector: 'app-enterprise',
	templateUrl: './enterprise.component.html',
	styleUrls: ['./enterprise.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnterpriseComponent implements OnInit {
	enterprises: IConfigEnterprise[];

	constructor(
		private router: Router,
		private config: ConfigService
	) {}

	public ngOnInit(): void {
		this.enterprises = this.config.getConfig(['enterprises']).enterprises;
	}

	public selectEnterprise(url, isMainEnterprise): void {
		if (isMainEnterprise) {
			this.router.navigate([url]);
		} else {
			window.location.href = url;
		}
	}
}
