<div class="app-input g-unselectable">
	<input
		class="form-control"
		placeholder="{{placeholder}}"
		(input)="onInput($event)"
		[type]="type"
		[required]="required"
		[disabled]="disabled"
		(blur)="onTouched()"
		[ngClass]="!!validate ? {
			'is-invalid': isInvalid(),
			'is-valid': isValid()
		} : ''"
	>
	<app-icon class="icon" *ngIf="svgIcon" [name]="svgIcon" />

	@if (!!validate && (control.dirty || control.touched) && control.hasError('required')) {
		<app-icon class="icon red" name="important" />
	} @else if (!!validate === true && (control.dirty || control.touched) && !control.errors) {
		<app-icon class="icon green" name="done" />
	}
</div>

