@for (item of enterprises; track item.url) {
	<div
		class="card g-unselectable"
		(click)="selectEnterprise(item.url, item.isMainEnterprise)"
	>
		<mat-icon
			class="card__icon"
			[svgIcon]="item.logoUrl"
		></mat-icon>
		<p class="card__text">{{ item.description }}</p>
	</div>
}
