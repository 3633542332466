<app-column
	class="content"
	typeColumn="content"
>
	<div header>О системе</div>

	<div
		content
		class="flex-col g-al-items-center"
	>
		<div style="text-align: center" class="flex-col g-col-md-2 g-al-items-center g-unselectable">
			<app-frosted-glass class="full-width">
				<div class="logo text-center">
					<img
						src="../../../../assets/img/owl-logo-transparent.png"
						alt="Owl SCADA"
					/>
				</div>
			</app-frosted-glass>
			<hr />
			<div>
				<p><strong>OWL SCADA 3.0</strong> – система мониторинга параметров работы технологического оборудования</p>
				<p>
					Свидетельство о государственной регистрации программ для ЭВМ <br />
					<strong>№2018618727</strong>
				</p>
				<p>
					Инструкция по использованию OWL Scada расположена по ссылке: <br />
					<a
						title="Скачать инструкцию"
						href="{{ instructionPath }}"
						target="_blank"
					>Инструкция
						<app-icon name="cloud-download"></app-icon>
					</a>
				</p>
			</div>

			<hr class="full-width" />
		</div>

		<div
			[@animateFadeIn]="showDev ? 'show' : 'hide'"
			style="text-align: center"
			class="row align-items-center center g-col-md-2"
		>
			<p class="g-unselectable">Разработчики системы:</p>
			<p class="developers">
				{{ devStr }}
			</p>
		</div>
	</div>
</app-column>
