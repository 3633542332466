import * as THREE from 'three';
// @ts-ignore
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
// @ts-ignore
import TWEEN from 'three/addons/libs/tween.module.js';

const sizes = {
	width: window.innerWidth,
	height: window.innerHeight,
};

export function setupThreeJs() {
	const threeJsObject: any = getThreeJsObject();
	const { scene, canvas, camera, renderer, controls } = threeJsObject;

	camera.position.z = 8;

	scene.add(camera);
	renderer.setSize(sizes.width, sizes.height);
	renderer.render(scene, camera);

	handlerTickAnimation.call(threeJsObject);

	threeJsObject.handlerResize = handlerResizeWindow.bind(threeJsObject);
	threeJsObject.clear = clearThreeJsObject.bind(threeJsObject);

	window.addEventListener('resize', threeJsObject.handlerResize);

	return threeJsObject;
}

function getThreeJsObject() {
	const scene = new THREE.Scene();
	const canvas = document.querySelector('.canvas');
	const camera = new THREE.PerspectiveCamera(
		75, sizes.width / sizes.height,
	);
	const renderer = new THREE.WebGLRenderer({
		canvas,
		alpha: true,
	});
	const controls = new OrbitControls(camera, canvas);
	controls.enableDamping = true;

	return { scene, canvas, camera, renderer, controls };
}

function handlerTickAnimation() {
	this.isRun = true;

	const tick = () => {
		this.controls.update();
		TWEEN.update();

		this.renderer.render(this.scene, this.camera);

		if (this.isRun) {
			window.requestAnimationFrame(tick);
		}
	};
	tick();
}

function handlerResizeWindow() {
	// Обновляем размеры
	sizes.width = window.innerWidth;
	sizes.height = window.innerHeight;

	// Обновляем соотношение сторон камеры
	this.camera.aspect = sizes.width / sizes.height;
	this.camera.updateProjectionMatrix();

	// Обновляем renderer
	this.renderer.setSize(sizes.width, sizes.height);
	this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
	this.renderer.render(this.scene, this.camera);
}

function clearThreeJsObject() {
	this.canvas.remove();
	this.camera.clear();
	this.renderer.clear();
	window.removeEventListener('resize', this.handlerResize);
	this.isRun = false;
}
