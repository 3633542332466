<div *ngIf="!!notificationList?.length; else noContent"
	 class="list full-height full-width"
>
	<div class="flex-row mb-8">
		<app-button
			*ngIf="EListType.actual === this.currentListType && EListType.actual === this.listType"
			class="g-button-success-square mr-8"
			svgIcon="save"
			(click)="readAllNotifications()"
		>
			В архив
		</app-button>

		<app-button
			class="g-button-danger-square mr-8"
			svgIcon="trash"
			(click)="deleteAllNotifications()"
		>
			Удалить все
		</app-button>

	</div>

	<div
		*appAdaptive="{min: 768}"
		class="table-responsive tableFixHead full-width full-height"
	>
		<app-table class="table mb-0"
				   [data]="notificationList"
				   [itemSize]="'90'"
		>
			<ng-container *viewItem>
				<app-table-header [sizeColumn]="{minSize: '85px', maxSize: '1fr'}">
					Ключ задачи
				</app-table-header>

				<app-table-item *lineValue="let element">
					<a
						*ngIf="element.taskKey && element.taskId"
						appStopPropagation
						matTooltip="Перейти к задаче"
						class="g-badge g-badge-primary key"
						(mouseup)="goToTask(element.taskId, $event)"
					>
						{{ element.taskKey }}
					</a>
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header [sizeColumn]="{minSize: '85px', maxSize: '2fr'}">
					Название
				</app-table-header>

				<app-table-item *lineValue="let element">
					{{ element.title }}
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header [sizeColumn]="{minSize: '85px', maxSize: '1fr'}">
					Оборудование
				</app-table-header>

				<app-table-item *lineValue="let element" class="flex-col">
					<a
						class="pointer link"
						matTooltip="Перейти к станку"
						(mouseup)="goToUnit(element.unitId, $event)"
					>
						{{ element.unitName }}
					</a>
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header [sizeColumn]="{minSize: '85px', maxSize: '1fr'}">
					Дата
				</app-table-header>

				<app-table-item *lineValue="let element">
					{{ element.date }}
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header [sizeColumn]="{minSize: '85px', maxSize: '1fr'}">
					Подписка
				</app-table-header>
				<app-table-item *lineValue="let element">
					{{ element.subscriptionName }}
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header [sizeColumn]="{minSize: '85px', maxSize: '110px'}">
					Отчеты
				</app-table-header>

				<app-table-item *lineValue="let element">
					<app-button
						*ngIf="element.fileList?.length"
						class="g-button-primary-empty"
						svgIcon="documents-outline"
						appPopover
						[popoverTemplateRef]="downloadDialog"
					>
						Отчеты
					</app-button>

					<ng-template #downloadDialog>
						<div appStopPropagation class="flex-col g-background-middle g-border-radius-05 gap-4">
							<a *ngFor="let file of element.fileList; let i = index"
							   [href]="file"
							   [download]="getFileName(element.subscriptionMessageHistoryId, file)"
							>
								{{ getFileName(element.subscriptionMessageHistoryId, file) }}
							</a>
						</div>
					</ng-template>
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header [sizeColumn]="{minSize: '82px', maxSize: '96px'}">
				</app-table-header>

				<app-table-item *lineValue="let element">
					<div class="flex-row mt-8">
						<app-button
							*ngIf="EListType.actual === this.currentListType && EListType.actual === this.listType"
							class="g-button-success-square button-icon mr-8"
							svgIcon="save"
							[class.btnSaveVisible]="true"
							(click)="readNotification(element)"
						></app-button>
						<app-button
							class="g-button-danger-square button-icon"
							svgIcon="trash"
							[class.btnSaveVisible]="true"
							(click)="deleteNotification(element)"
						>
						</app-button>
					</div>
				</app-table-item>
			</ng-container>
		</app-table>
	</div>
	<div
		*appAdaptive="{max: 768}"
		class="mobile__wrapper"
	>
		<div
			*ngFor="let item of notificationList"
			class="card"
		>
			<div>
				<span
					class="link"
					[routerLink]="[ '/monitoring/unit/', item.unitId ]"
				>
					{{ item.unitName }}
				</span>

				<app-button
					*ngIf="EListType.actual === this.currentListType && EListType.actual === this.listType"
					class="g-button-success-square mr-4"
					svgIcon="save"
					[class.btnSaveVisible]="true"
					(click)="readNotification(item)"
				></app-button>
				<app-button
					class="g-button-danger-square"
					svgIcon="trash"
					[class.btnSaveVisible]="true"
					(click)="deleteNotification(item)"
				></app-button>
			</div>

			<span>Дата: {{ item.date }}</span>
			<span>Подписка: {{ item.subscriptionName }}</span>

			<div *ngIf="!item.taskId; else taskRoute">{{ item.title }}</div>
			<ng-template #taskRoute>
				<div>
					<a [routerLink]="['/service-desk/task-list/task/', item.taskId]">
						{{ item.title }}
					</a>
				</div>
			</ng-template>
		</div>
	</div>
</div>

<ng-template #noContent>
	<span *ngIf="EListType.archive === currentListType && EListType.archive === listType"
		  class="no-content g-unselectable"
	>
		Архивных сообщений нет
	</span>

	<span *ngIf="EListType.actual === currentListType && EListType.actual === listType"
		  class="no-content g-unselectable"
	>
		Уведомлений нет. Чтобы подписаться на уведомления - откройте станицу оборудования и нажмите на кнопку
		<mat-icon svgIcon="notifications">
		</mat-icon>
	</span>
</ng-template>

