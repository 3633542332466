import {EasterEggComponent} from '@shared/components/easter-egg/easter-egg.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

@NgModule({
	declarations: [EasterEggComponent],
	exports: [EasterEggComponent],
	imports: [
		CommonModule
	]
})
export class EasterEggModule {}
