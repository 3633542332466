import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { IRoutePart } from '@shared/models/route-part';
import { UnitsSelector } from '@store/units-store/units.selector';
import { LocationSelector } from '@store/location-store/location.selector';

@Injectable()
export class BreadcrumbsService {
	public breadcrumbs: BehaviorSubject<IRoutePart[]>;

	constructor(
		private activeRoute: ActivatedRoute,
		private unitsSelector: UnitsSelector,
		private locationSelector: LocationSelector,
	) {
		this.breadcrumbs = new BehaviorSubject<IRoutePart[]>([]);
	}

	public generateBreadcrumbs(): IRoutePart[] {
		const parts = this.generateRouteParts(this.activeRoute.snapshot);
		let breadcrumbs: IRoutePart[] = cloneDeep(parts).reverse();
		if (parts.length > 0 && parts[0].url?.indexOf('unit') !== -1 && parts[0].url?.endsWith('s') === false) {
			const unit = this.unitsSelector.getUnitById(parts[0].params.id);

			this.setLocationPart(breadcrumbs, 0, parts[1]);

			this.setLocationPart(breadcrumbs, 1, this.getPartMap());
			breadcrumbs[1].enableLink = true;

			// @ts-ignore
			this.setLocationPart(
				breadcrumbs,
				2,
				this.getLocation(unit?.location?.id),
			);

			this.setLocationPart(
				breadcrumbs,
				3,
				this.getUnit(parts[0].params.id),
			);
		} else if (parts.length > 0 && parts[0].breadcrumb === 'Здание') {
			breadcrumbs = this.setUrlForBreadcrumbsSegments(cloneDeep(parts));

			const mapPartOfBreadcrumb = breadcrumbs[1];
			mapPartOfBreadcrumb.enableLink = true;

			this.setLocationPart(
				breadcrumbs,
				2,
				this.getLocation(breadcrumbs[2].params.id),
			);
		}

		this.breadcrumbs.next(breadcrumbs);

		return breadcrumbs;
	}

	private generateRouteParts(snapshot: ActivatedRouteSnapshot): IRoutePart[] {
		let routeParts: IRoutePart[] = [];

		if (snapshot) {
			if (snapshot.firstChild) {
				routeParts = routeParts.concat(
					this.generateRouteParts(snapshot.firstChild),
				);
			}

			if (snapshot.url.length) {
				routeParts.push({
					title: snapshot.data['title'],
					breadcrumb: snapshot.data['breadcrumb'],
					url: snapshot.url[0].path,
					urlSegments: snapshot.url,
					params: snapshot.params,
				});
			}
		}

		return routeParts;
	}

	private setUrlForBreadcrumbsSegments(
		routeParts: IRoutePart[],
	): IRoutePart[] {
		let url = '';

		return routeParts.reverse().map(item => {
			if (!item.url) {
				return item;
			}

			item.url = url + '/' + item.url;
			url = item.url;

			return item;
		});
	}

	private setLocationPart(
		routeParts: IRoutePart[],
		index: number,
		part: IRoutePart,
	): void {
		if (routeParts.length >= index + 1) {
			routeParts[index] = part;
		} else {
			routeParts.push(part);
		}
	}

	private getLocation(id: number): IRoutePart {
		let location = this.locationSelector.getLocationById(id);

		while (
			!location?.firstLevel &&
			!!location?.parent?.id &&
			location.parent.id !== 3
			) {
			location = this.locationSelector.getLocationById(location.parent.id);
		}

		return {
			breadcrumb: !!location ? location.name : 'Здание',
			params: {
				id: !!location && !!location.parent ? location.parent.id : id,
			},
			title: 'Здание',
			url: `/monitoring/map/${!!location ? location.id : id}`,
		};
	}

	private getUnit(id: number): IRoutePart {
		const unit = this.unitsSelector.getUnitById(id);

		return {
			breadcrumb: unit?.unitType?.name,
			params: { id: unit?.id },
			title: 'Unit',
			url: `monitoring/unit/${unit?.id}`,
		};
	}

	private getPartMap(): IRoutePart {
		return {
			breadcrumb: 'Карта',
			params: {},
			title: 'Карта',
			url: '/monitoring/map',
		};
	}
}
