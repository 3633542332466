@if (isShowEasterEgg) {
	<app-easter-egg (stop)="clearCounterEasterEgg()"></app-easter-egg>
}

<aside
	class="sidebar g-unselectable"
	(mouseenter)="mouseEnter()"
	(mouseleave)="mouseLeave()"
>
	<nav class="main-menu">
		<div
			class="logo"
			(click)="toggleSidebar()"
		>
			<mat-icon
				svgIcon="owl-logo"
				class="logo-icon"
			></mat-icon>
			<span>OWL SCADA</span>
		</div>

		@for (item of menu; track item.route) {
			<div
				class="card"
				[class.disabled]="item.disabled"
			>
				<div
					class="card-header"
					id="heading{{$index}}"
					[ngClass]="activeRouteIndex === $index
				 	? (platform.IOS ? 'active-ios' : 'active')
				 	: 'non-active'"
					(click)="collapse(item, $index)"
				>
					@if (!item.route) {
						<div
							class="tab"
							data-toggle="collapse"
							data-target="#{{$index}}"
							aria-expanded="false"
							aria-controls="{{$index}}"
						>
							<mat-icon
								class="menu-icon"
								[svgIcon]="item.icon"
							></mat-icon>

							<div
								[ngClass]="{isSidebarShown: isSidebarShown}"
								class='menu-el title'
							>{{ item.title }}
							</div>

							<div
								class="toggle-button mini menu-el icon-chevron"
								[class.switch]="item.iconState === 'rotated'"
							>
								<span class="arrow"></span>
							</div>
						</div>
					}

					@if (item.route) {
						<a
							class="tab"
							(mouseup)="goToPage(item.route, $event)"
						>
							<mat-icon
								class="menu-icon"
								[svgIcon]="item.icon"
							></mat-icon>

							<div
								[ngClass]="{isSidebarShown: isSidebarShown}"
								class='menu-el'
							>
								{{ item.title }}
							</div>
						</a>
					}
				</div>

				@if (item.accordionState === 'expanded') {
					<div
						id="{{i}}"
						class="collapsible-content"
						aria-labelledby="heading{{i}}}"
						data-parent="#accordion"
						@untwistingAnimation
					>
						@for (children of item.children; track children.path) {
							<a
								class="card-body"
								[ngClass]="activeCollapsibleItemRoute===children.route ? 'active': 'non-active'"
								(mouseup)="goToPage(children.route, $event)"
							>
								{{ children.text }}
							</a>
						}
					</div>
				}

			</div>
		}


	</nav>

	<div
		class="toggle-button"
		[class.switch]="isSidebarShown"
		(click)="toggleSidebar()"
	>
		<span class="arrow"></span>
		<span class="arrow"></span>
	</div>
</aside>

<div
	[class.zone-close]="isSidebarShown"
	(click)="sidebarService.isSidebarShown.next(!isSidebarShown)"
></div>
