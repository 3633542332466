import {animate, style, transition, trigger} from '@angular/animations';

export const scaleAnimation = trigger('scaleAnimation', [
	transition('void => *', [
		style({
			scale: 0.8,
			transform: 'translate(150px, -150px)',
			opacity: 0,
		}),
		animate(
			'.15s ease-in-out',
			style({
				scale: 1,
				transform: 'translate(0, 0)',
				opacity: 1,
			})
		),
	]),
	transition('* => void', [
		style({scale: 1, transform: 'translate(0, 0)', opacity: 1}),
		animate(
			'.15s ease-in-out',
			style({
				scale: 0.8,
				transform: 'translate(50px, -150px)',
				opacity: 0,
			})
		),
	]),
]);
