// @ts-ignore
import {STLLoader} from 'three/addons/loaders/STLLoader.js';
import * as THREE from 'three';

export function getModel() {
	const material = getMaterial();
	const loader = new STLLoader();

	return new Promise((resolve, reject) => {
		loader.load(
			`assets/three-js/models/model1.stl`,
			(geometry) => {
				const mesh = new THREE.Mesh(geometry, material);

				mesh.scale.x = 0.02;
				mesh.scale.y = 0.02;
				mesh.scale.z = 0.02;

				resolve(mesh);
			},
			() => {
			},
			reject,
		);
	});
}

export function decoratorInvisible(model) {
	model.scale.x = 0;
	model.scale.y = 0;
	model.scale.z = 0;

	return model;
}

export function getMaterial() {
	const textureLoader = new THREE.TextureLoader();
	const metalTexture = textureLoader.load(
		`assets/three-js/textures/texture4.png`,
	);

	return new THREE.MeshLambertMaterial({map: metalTexture});
}

export function getLight(side: 'front' | 'back' = 'front') {
	const pointLight = new THREE.PointLight(0xffffff, 20);

	pointLight.position.x = 2;
	pointLight.position.y = 3;
	pointLight.position.z = side === 'front' ? 4 : -4;

	return pointLight;
}
